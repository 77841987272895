exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-galeria-js": () => import("./../../../src/pages/Galeria.js" /* webpackChunkName: "component---src-pages-galeria-js" */),
  "component---src-pages-gold-finisher-js": () => import("./../../../src/pages/GoldFinisher.js" /* webpackChunkName: "component---src-pages-gold-finisher-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-listastartowa-js": () => import("./../../../src/pages/listastartowa.js" /* webpackChunkName: "component---src-pages-listastartowa-js" */),
  "component---src-pages-objazd-js": () => import("./../../../src/pages/objazd.js" /* webpackChunkName: "component---src-pages-objazd-js" */),
  "component---src-pages-regulamin-js": () => import("./../../../src/pages/regulamin.js" /* webpackChunkName: "component---src-pages-regulamin-js" */),
  "component---src-pages-trasa-js": () => import("./../../../src/pages/trasa.js" /* webpackChunkName: "component---src-pages-trasa-js" */),
  "component---src-pages-urr-23-cz-1-js": () => import("./../../../src/pages/urr23cz1.js" /* webpackChunkName: "component---src-pages-urr-23-cz-1-js" */),
  "component---src-pages-urr-23-cz-2-js": () => import("./../../../src/pages/urr23cz2.js" /* webpackChunkName: "component---src-pages-urr-23-cz-2-js" */),
  "component---src-pages-urr-23-cz-3-js": () => import("./../../../src/pages/urr23cz3.js" /* webpackChunkName: "component---src-pages-urr-23-cz-3-js" */),
  "component---src-pages-urr-24-cz-1-js": () => import("./../../../src/pages/urr24cz1.js" /* webpackChunkName: "component---src-pages-urr-24-cz-1-js" */),
  "component---src-pages-urr-24-cz-2-js": () => import("./../../../src/pages/urr24cz2.js" /* webpackChunkName: "component---src-pages-urr-24-cz-2-js" */),
  "component---src-pages-urr-24-cz-3-js": () => import("./../../../src/pages/urr24cz3.js" /* webpackChunkName: "component---src-pages-urr-24-cz-3-js" */),
  "component---src-pages-urr-24-cz-4-js": () => import("./../../../src/pages/urr24cz4.js" /* webpackChunkName: "component---src-pages-urr-24-cz-4-js" */),
  "component---src-pages-wyniki-js": () => import("./../../../src/pages/Wyniki.js" /* webpackChunkName: "component---src-pages-wyniki-js" */),
  "component---src-pages-zapisy-js": () => import("./../../../src/pages/zapisy.js" /* webpackChunkName: "component---src-pages-zapisy-js" */),
  "component---src-pages-zawody-js": () => import("./../../../src/pages/Zawody.js" /* webpackChunkName: "component---src-pages-zawody-js" */)
}

